import { inject, Injectable } from "@angular/core";
import { WINDOW_TOKEN } from "@kno2/core/tokens";

@Injectable({
    providedIn: "root"
})
export class LocalStorageService {
    private readonly prefix: string;
    private readonly window = inject(WINDOW_TOKEN);

    constructor(prefix: string) {
        this.prefix = prefix;
    }

    /**
     * Sets the value for the given key in the local storage.
     *
     * If the value is an object or an array, it will be stringified.
     *
     * @param key The key to set the value for.
     * @param value The value to set for the given key.
     */
    public set(key: string, value: unknown): void {
        if (typeof value === "object" || Array.isArray(value)) {
            value = JSON.stringify(value);
        }

        this.window.localStorage.setItem(`${this.prefix}.${key}`, value as string);
    }

    /**
     * Gets the value for the given key from the local storage.
     *
     * If the value is null or undefined it will return null.
     * If the value is an object or an array, it will be parsed and returned.
     * If the value is a string, it will be returned as is.
     *
     * @param key The key to get the value for.
     * @returns The value for the given key.
     */
    public get<T = unknown>(key: string): T {
        const item = this.window.localStorage.getItem(`${this.prefix}.${key}`);

        if (!item || item === "undefined") return null;

        try {
            if (!item) return null;
            return JSON.parse(item);
        } catch {
            return <T>item;
        }
    }

    /**
     * Removes the value for the given key from the local storage.
     *
     * @param key The key to remove the value for.
     */
    public remove(key: string): void {
        this.window.localStorage.removeItem(`${this.prefix}.${key}`);
    }

    /**
     * Removes all values from the local storage that have the prefix.
     */
    public clearAll(): void {
        this.keys().forEach((key) => this.window.localStorage.removeItem(key));
    }

    /**
     * This method returns an array of all keys in the local storage that have the prefix.
     *
     * @returns An array of all keys in the local storage that have the prefix.
     */
    public keys(): string[] {
        return Object.keys(this.window.localStorage).filter((key) => key.startsWith(this.prefix));
    }
}
