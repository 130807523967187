import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { SessionService } from "@kno2/shared/ui/session";

export const roleGuard: CanActivateFn = (route) => {
    const router = inject(Router);
    const sessionService = inject(SessionService);

    const canActivate = sessionService.userInRole(route.data?.roles);

    if (canActivate) return true;

    return router.parseUrl("/unauthorized");
};
