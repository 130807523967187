routeSubscriptionConfig.$inject = ["$rootScope", "$transitions", "SubscriptionService"];

/**
 *  Shows the subscription upgrade prompt if the user navigates to an area that requires subscription.
 */
export function routeSubscriptionConfig($rootScope, $transitions, SubscriptionService) {
    // Expose subscription methods globally
    $rootScope.promptUpgradeModal = SubscriptionService.checkAndShowPrompt;

    $transitions.onStart({}, function (transition) {
        var to = transition.$to();
        var resource = (to.data && to.data.resource) || null;
        if (resource) {
            if (SubscriptionService.hasPromptAction(resource))
                return SubscriptionService.showPrompt(resource)
                    .catch(angular.noop)
                    .then(function () {
                        return false;
                    });

            if (SubscriptionService.hasCancelAction(to.data.resource)) return false;
        }
    });
}
