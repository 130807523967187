import { Component, OnInit, inject, output } from "@angular/core";
import { FormBuilder, ReactiveFormsModule, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { UpgradeModule } from "@angular/upgrade/static";
import { SupportService } from "@kno2/data-access/support";
import { PopoverDirective } from "@kno2/shared/directives";
import { FormSelectComponent } from "@kno2/shared/ui";
import { SessionService } from "@kno2/shared/ui/session";
import { UAParser } from "ua-parser-js";
import { TOOLTIP_FEATURE_AREA, TOOLTIP_SUBMIT_A_TICKET } from "../support.constants";
import { SUPPORT_TICKET_AREAS, SUPPORT_TICKET_ISSUE_TEXT_PLACEHOLDER, SUPPORT_TICKET_SEVERITY } from "./support.submit-a-ticket.constants";

@Component({
    selector: "kno2-support-submit-a-ticket",
    standalone: true,
    imports: [ReactiveFormsModule, FormSelectComponent, PopoverDirective],
    templateUrl: "./support.submit-a-ticket.component.html"
})
export class SubmitATicketComponent implements OnInit {
    private readonly route = inject(ActivatedRoute);
    private readonly fb = inject(FormBuilder);
    private readonly supportService = inject(SupportService);
    private readonly sessionService = inject(SessionService);
    private readonly upgrade = inject(UpgradeModule);
    private readonly appConfig = this.upgrade.$injector.get("appConfig");
    private readonly notificationService = this.upgrade.$injector.get("NotificationService");

    protected expanded = false;
    protected isSubmitting = false;
    protected hasSubmitted = false;
    protected readonly toggleCollapse = output<void>();
    protected readonly sectionTooltip = TOOLTIP_SUBMIT_A_TICKET;
    protected readonly featureAreaTooltip = TOOLTIP_FEATURE_AREA;
    protected readonly areaOptions = SUPPORT_TICKET_AREAS(this.appConfig.brandDisplayName).map((name) => [name, name]);
    protected readonly severityOptions = SUPPORT_TICKET_SEVERITY(this.appConfig.brandDisplayName).map((name) => [name, name]);
    protected readonly issueTextPlaceholder = SUPPORT_TICKET_ISSUE_TEXT_PLACEHOLDER;
    protected readonly form = this.fb.group({
        area: [, Validators.required],
        severity: [, Validators.required],
        issueText: [, Validators.required]
    });

    public ngOnInit(): void {
        this.expanded = this.route.snapshot.routeConfig.path.endsWith("contact");
    }

    public async onSubmit(): Promise<void> {
        try {
            this.hasSubmitted = true;

            if (this.form.invalid) return;

            this.isSubmitting = true;

            const { browser, engine, os } = new UAParser().getResult();
            const { fullName, userName: emailAddress } = this.sessionService.getProfile();
            const payload = {
                ...this.form.value,
                fullName,
                emailAddress,
                userAgent: {
                    browser,
                    engine,
                    os
                }
            };
            await this.supportService.submitTicket(payload);
            this.notificationService.success("The support ticket was submitted successfully.");
            this.form.reset();
            this.hasSubmitted = false;
        } catch (response) {
            this.notificationService.errorToaster(response?.error?.message || "There was an error saving this support ticket.");
        } finally {
            this.isSubmitting = false;
        }
    }
}
