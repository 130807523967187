(function () {
    "use strict";

    angular.module("kno2.services").factory("FeatureService", FeatureService);

    FeatureService.$inject = ["$q", "$http", "_", "SessionService", "localStorageService"];

    function FeatureService($q, $http, _, SessionService, localStorageService) {
        var _features = localStorageService.get("features");
        var _requesting = false;
        var _deferred;

        return {
            load: load,
            isEnabled: isEnabled,
            isAnyEnabled: isAnyEnabled
        };

        function load(options) {
            const { organizationId } = SessionService.getProfile();

            if (options && options.reload) _features = null;
            if (_features) return $q.resolve(_features);

            // handle simultaneous requests so the features api isn't queried mulitple times
            if (_requesting) {
                if (!_deferred) _deferred = $q.defer();
                return _deferred.promise;
            }
            _requesting = true;

            return $http.get(`/api/organizations/${organizationId}/features`).then(function (response) {
                _features = response.data;
                localStorageService.set("features", _features);
                if (_deferred) {
                    _deferred.resolve(_features);
                    _deferred = null;
                }
                _requesting = false;
                return _features;
            });
        }

        /**
         * Accepts a string with a single value, a csv string and an array of strings.
         * @param {string|string[]} features
         */
        function isEnabled(features) {
            if (!features) return false;

            var array;
            if (_.isString(features))
                array = features.split(",").reduce(function (result, str) {
                    var value = str.trim();
                    if (value) result.push(value);
                    return result;
                }, []);
            else if (_.isArray(features)) array = features;
            else return false;

            if (!array.length) return false;

            var matches = _.intersection(_features, array);
            return matches.length === array.length;
        }

        /**
         * Accepts an array of strings and will return true if any are enabled
         * @param {string[]} features
         */
        function isAnyEnabled(featuresArray) {
            if (!_.isArray(featuresArray) || !featuresArray.length) {
                return false;
            }
            let matches = _.intersection(_features, featuresArray);
            return matches.length > 0;
        }
    }
})();
