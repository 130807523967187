import _ from "lodash";
import templateUrl from "./ip-addresses.component.html";

export const serviceAccountipAddressesComponent = {
    controller: ServiceAccountIpAddressesController,
    templateUrl: templateUrl,
    bindings: {
        limit: "<",
        addresses: "<",
        currentAddress: "<",
        onSave: "&"
    }
};

ServiceAccountIpAddressesController.$inject = ["$timeout", "SessionService", "NotificationService"];

export function ServiceAccountIpAddressesController($timeout, SessionService, NotificationService) {
    var ctrl = this;

    ctrl.$onInit = $onInit;
    ctrl.showAddMyAddress = showAddMyAddress;
    ctrl.add = add;
    ctrl.save = save;

    function $onInit() {
        ctrl.currentAddress = ctrl.currentAddress || SessionService.getProfile().ipAddress;
        if (!ctrl.currentAddress)
            SessionService.getClientIp().then(function (response) {
                ctrl.currentAddress = response;
            });
        ctrl.addresses = ctrl.addresses || [];
        ctrl.ipAddressRegex = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?(\/([0-9]|[12][0-9]|3[0-2]))?)$/;
    }

    function showAddMyAddress() {
        return ctrl.currentAddress && ctrl.addresses.indexOf(ctrl.currentAddress) === -1;
    }

    function add(address) {
        ctrl.addresses.push(address);
    }

    function save() {
        ctrl.addressesForm.$setSubmitted();

        // remove blanks and duplicates
        ctrl.addresses = _.chain(ctrl.addresses)
            .filter(function (a) {
                // undefined means invalid, don't remove
                return a === undefined || !!_.trim(a);
            })
            .uniq()
            .value();

        if (ctrl.limit && ctrl.addresses && ctrl.addresses.length > ctrl.limit)
            return NotificationService.errorToaster(`Number of Whitelisted IP Addresses Cannot be Greater than ${ctrl.limit}`, true);

        // allow validation to recheck with timeout
        $timeout(function () {
            if (ctrl.addressesForm.$invalid) return;

            ctrl.onSave({ $addresses: ctrl.addresses });
            ctrl.addressesForm.$setPristine();
        });
    }
}
