import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { UpgradeModule } from "@angular/upgrade/static";
import { SessionService } from "@kno2/shared/ui/session";

export const featureGuard: CanActivateFn = async (route, state) => {
    const router = inject(Router);
    const sessionService = inject(SessionService);
    const upgrade = inject(UpgradeModule);
    const featureService = upgrade.$injector.get("FeatureService");

    await featureService.load();

    const canActivate = featureService.isEnabled(route.data?.features);
    if (canActivate) return true;

    const redirectUrl = sessionService.getDefaultRouteForActiveRole();
    return router.parseUrl(redirectUrl);
};
