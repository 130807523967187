import { Injectable, inject, signal } from "@angular/core";
import { UpgradeModule } from "@angular/upgrade/static";
import { AuthService } from "@kno2/core/services";
import { ContactUploadLatest } from "@kno2/data-access/contacts";
import { AppConfigToken } from "@kno2/interop";
import { Socket, default as io } from "socket.io-client";
import { SessionService } from "../ui/session";

const SOCKET_CONNECT = "connect";
const SOCKET_CONNECT_ERROR = "connect_error";
const SOCKET_ERROR = "error";
const SOCKET_CONTACTS_UPLOAD_FINISHED = "ContactsUploadHasFinished";
const SOCKET_AUTH_REQUEST = "authRequest";
const SOCKET_AUTH_RESULT = "authRequestResult";

export type ContactsUploadHasFinishedResult = {
    userId: string;
    contactUploadLatest: ContactUploadLatest;
};

@Injectable({
    providedIn: "root"
})
export class SocketService {
    private readonly sessionService = inject(SessionService);
    private readonly authService = inject(AuthService);
    private readonly upgrade = inject(UpgradeModule);
    private readonly notificationService = this.upgrade.$injector.get("NotificationService");

    private readonly appConfig = inject(AppConfigToken);

    private client: Socket;
    public readonly contactsUploadHasFinished = signal({} as ContactUploadLatest);

    public init() {
        const { socketUrl } = this.appConfig;

        if (!socketUrl) {
            console.log(`${this.constructor.name}: No socket URL provided. Socket service will not be initialized.`);
            return;
        }

        this.client = io(socketUrl);
        this.client.connect();

        this.client.on(SOCKET_CONNECT, () => this.handleConnected());
        this.client.on(SOCKET_CONNECT_ERROR, (err) => this.handleConnectError(err));
        this.client.on(SOCKET_ERROR, (err) => this.handleError(err));
        this.client.on(SOCKET_AUTH_RESULT, (result) => this.handleAuthResult(result));

        this.client.on(SOCKET_CONTACTS_UPLOAD_FINISHED, (result: ContactsUploadHasFinishedResult) => this.handleContactsUploadHasFinished(result));
    }

    public disconnect(): void {
        this.client?.disconnect();
    }

    private async handleConnected(): Promise<void> {
        const { userId } = this.sessionService.getProfile();
        const authToken = this.authService.accessToken;

        this.client.emit(SOCKET_AUTH_REQUEST, {
            apiUrl: this.appConfig.baseApiUrl,
            authToken,
            userId
        });
    }

    private handleConnectError(err): void {
        console.error(`${this.constructor.name}: The socket connection was refused.`, err);
        this.disconnect();
    }

    private handleError(err): void {
        console.error(`${this.constructor.name}: An unknown error occurred `, err);
    }

    private handleAuthResult(result: any) {
        if (!result.success) {
            this.disconnect();
        }
    }

    private handleContactsUploadHasFinished(result: ContactsUploadHasFinishedResult) {
        this.contactsUploadHasFinished.set(result.contactUploadLatest);

        const { lastUpload } = result.contactUploadLatest;
        const { status, errorMessage } = lastUpload;

        if (status === "Failure") {
            this.notificationService.errorToaster(errorMessage);
        } else if (status === "Processing") {
            this.notificationService.warning("The file could not be imported as an upload is currently in progress.");
        } else if (status === "Success") {
            this.notificationService.success("Contacts imported successfully.");
        }
    }
}
