import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { SessionService } from "@kno2/shared/ui/session";

export const profileGuard: CanActivateFn = async (route, state) => {
    const sessionService = inject(SessionService);

    await sessionService.getProfileAsync();

    return true;
};
