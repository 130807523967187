import { Location } from "@angular/common";
import { Component, effect, inject } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { NavigationEnd, Router } from "@angular/router";
import { UpgradeModule } from "@angular/upgrade/static";
import { RedirectService } from "@kno2/shared/services";
import { SessionService } from "@kno2/shared/ui/session";

@Component({
    selector: "kno2-empty",
    standalone: true,
    imports: [],
    template: ``
})
export class EmptyComponent {
    private readonly location = inject(Location);
    private readonly router = inject(Router);
    private readonly redirectService = inject(RedirectService);
    private readonly sessionService = inject(SessionService);
    private readonly upgrade = inject(UpgradeModule);
    private readonly $urlService = this.upgrade.$injector.get("$urlService");

    private routerEvent = toSignal(this.router.events);

    public constructor() {
        effect(() => {
            if (this.routerEvent() instanceof NavigationEnd) {
                this.location.replaceState(this.router.url);
            }
        });
    }

    public ngOnInit(): void {
        const matchResult = this.$urlService.match({ path: this.router.url });

        if (matchResult.rule.state.name === "otherwise") {
            const redirectUrl = this.redirectService.getRedirectUrl() || this.sessionService.getDefaultRouteForActiveRole().replace("admin/", "/");
            this.location.replaceState(redirectUrl);
        }
    }
}
