import { inject, Injectable } from "@angular/core";
import { LocalStorageService } from "../local-storage/local-storage.service";

@Injectable({
    providedIn: "root"
})
export class RedirectService {
    private readonly redirectUrlKey = "redirectUrl";
    private readonly localStorageService = inject(LocalStorageService);

    public isValidRedirectUrl(redirectUrl: string): boolean {
        const invalidUrls = [
            /^\/unauthorized/,
            /^\/access-denied/,
            /^\/auth-error/,
            /^\/callback/,
            /^\/account\/login/,
            /^\/account\/register/,
            /^\/account\/logout/,
            /^\/\?email=/,
            /^\/$/,
            /^\/admin$/
        ];

        const isValidUrl = !invalidUrls.some((pattern) => pattern.test(redirectUrl));

        return isValidUrl;
    }

    public async setRedirectUrl(redirectUrl: string): Promise<void> {
        if (this.isValidRedirectUrl(redirectUrl)) {
            this.localStorageService.set(this.redirectUrlKey, redirectUrl);
        }
    }

    public getRedirectUrl(remove = false): string {
        let redirectUrl = this.localStorageService.get<string>(this.redirectUrlKey);

        if (remove) this.localStorageService.remove(this.redirectUrlKey);
        return redirectUrl;
    }
}
