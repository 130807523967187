import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { SessionService } from "@kno2/shared/ui/session";
import { WINDOW_TOKEN } from "../tokens";

export const rootGuard: CanActivateFn = async (route, state) => {
    const router = inject(Router);
    const window = inject(WINDOW_TOKEN);
    const sessionService = inject(SessionService);

    const baseHref = "/";
    const baseElHref = window.document.querySelector("base")?.getAttribute("href");

    if (state.url !== baseHref) return true;

    const redirectUrl = sessionService.getDefaultRouteForActiveRole();
    if (!sessionService.isSysAdmin() && baseElHref !== "/admin/") return router.parseUrl(redirectUrl);

    window.location.href = redirectUrl;
    return false;
};
