import { inject, Injectable } from "@angular/core";
import { UpgradeModule } from "@angular/upgrade/static";
import { AuthService as Auth0Service, AuthenticationError, AuthorizationParams } from "@auth0/auth0-angular";
import { LocalStorageService } from "@kno2/shared/services";
import { DateTime } from "luxon";
import { firstValueFrom } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class AuthService {
    private readonly auth0Service = inject(Auth0Service);
    private readonly upgrade = inject(UpgradeModule);
    private readonly localStorageService = inject(LocalStorageService);
    private readonly appConfig = this.upgrade.$injector.get("appConfig");

    private _accessToken: string;

    public get accessToken(): string {
        return this._accessToken;
    }

    private get expiresAt(): number {
        if (!this._accessToken) return DateTime.now().toSeconds();
        const accessTokenParts = this._accessToken.split(".")[1];
        const decodedAccessToken = JSON.parse(atob(accessTokenParts));

        return decodedAccessToken.exp;
    }

    private _connection: string;

    public get connection(): string {
        return this._connection;
    }

    public async login(params?: AuthorizationParams): Promise<void> {
        if (params?.connection) this._connection = params.connection;
        return firstValueFrom(this.auth0Service.loginWithRedirect({ authorizationParams: params }));
    }

    public async renewTokens(): Promise<void> {
        this._accessToken = await firstValueFrom(this.auth0Service.getAccessTokenSilently());
    }

    public isAuthenticated(): Promise<boolean> {
        return firstValueFrom(this.auth0Service.isAuthenticated$);
    }

    public isExpired(minSecondsToExpiration = 60): boolean {
        const currentTime = DateTime.now();
        const expirationTime = DateTime.fromSeconds(this.expiresAt);
        const timeDifference = expirationTime.diff(currentTime, "seconds").seconds;

        return timeDifference <= minSecondsToExpiration;
    }

    public isSingleSignOnUser(profile): boolean {
        return profile.authStrategy !== "usernamepassword" || !profile.authStrategy;
    }

    public async getError(): Promise<AuthenticationError> {
        return firstValueFrom(this.auth0Service.error$) as Promise<AuthenticationError>;
    }

    public async logout(): Promise<void> {
        const profileData = this.localStorageService.get("ProfileData") || {};
        const logoutUrl = this.isSingleSignOnUser(profileData) ? `${this.appConfig.appUrl}/account/logout` : this.appConfig.appUrl;

        this.localStorageService.remove("features");
        this.localStorageService.remove("redirectUrl");
        this.localStorageService.remove("ProfileData");
        this._accessToken = "";

        return firstValueFrom(
            this.auth0Service.logout({
                logoutParams: {
                    returnTo: logoutUrl
                }
            })
        );
    }
}
