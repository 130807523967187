import { Directive, TemplateRef, ViewContainerRef, inject, input } from "@angular/core";
import { NetworksService } from "@kno2/data-access/networks";
import { SessionService } from "@kno2/shared/ui/session";

@Directive({
    selector: "[kno2NetworkFeatureToggle]",
    standalone: true
})
export class NetworkFeatureToggle {
    private readonly sessionService = inject(SessionService);
    private templateRef = inject(TemplateRef);
    private viewContainer = inject(ViewContainerRef);
    private networkFeaturesService = inject(NetworksService);

    protected requiredFeatures = input.required<string[]>({ alias: "kno2NetworkFeatureToggle" });

    public async ngOnInit(): Promise<void> {
        const { administerOfNetworkObjectId } = this.sessionService.getProfile();

        const featureStatuses = await this.networkFeaturesService.getNetworkFeatureStatuses(administerOfNetworkObjectId);
        const enabledFeatureNames = featureStatuses
            .flatMap((f) => [f, ...(f.childNetworkFeatures || [])])
            .filter((f) => f.isEnabled)
            .map((f) => f.name);

        if (this.requiredFeatures().every((f) => enabledFeatureNames.includes(f))) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }
}
